export const basicABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ownerOf',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'tokenURI',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  },
  {
    inputs: [],
    name: 'name',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        'internalType': 'bytes4',
        'name': 'interfaceId',
        'type': 'bytes4'
      }
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
];

export const getTokenMetadata = async (uri) => {
  try {
    let tokenURI = uri;
    let tokenId = uri.split('/').pop();
    if (tokenURI.startsWith('ipfs://')) {
      tokenURI = `https://ipfs.io/ipfs/${tokenURI.split('ipfs://')[1]}`;
    }
    let tokenMetadata, tokenMetadataJson;
    if (tokenURI.startsWith('data:application/json;base64')) {
      tokenMetadata = Buffer.from(tokenURI.replace('data:application/json;base64,', ''), 'base64');
      tokenMetadataJson = JSON.parse(tokenMetadata.toString());
    } else {
      tokenMetadata = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/getnft?url=${tokenURI}`);
      if (tokenMetadata.status === 500) return { image: null };
      tokenMetadataJson = await tokenMetadata.json();
    }
    if (tokenMetadataJson) {
      // fallback for not minted tokens that return string instead of JSON object
      if (typeof tokenMetadataJson === 'string' || tokenMetadataJson instanceof String) return { image: null };

      if (!tokenMetadataJson.token_id) tokenMetadataJson.token_id = tokenId;
      if (!tokenMetadataJson.token_uri) tokenMetadataJson.token_uri = uri;

      let imageUrl = tokenMetadataJson.image || tokenMetadataJson.image_data;
      if (tokenMetadataJson.static_image) {
        imageUrl = tokenMetadataJson.static_image;
        tokenMetadataJson.image = imageUrl;
      }
      if (imageUrl.startsWith('ipfs://')) {
        imageUrl = `https://ipfs.io/ipfs/${imageUrl.split('ipfs://')[1]}`;
        tokenMetadataJson.image = imageUrl;
      }
      if (imageUrl.startsWith('data:image/svg+xml;base64,')) {
        let imageData = Buffer.from(imageUrl.replace('data:image/svg+xml;base64,', ''), 'base64');
        imageUrl = imageData.toString();
        tokenMetadataJson.image = imageUrl;
      }
      if (imageUrl.startsWith('<svg ')) {
        tokenMetadataJson.image = imageUrl;
      }

      // TODO: test image load availability via HEAD
      // abort and show placeholder if image load > 5s

      return tokenMetadataJson;
    }
  } catch (err) {
    console.log('error metadata', err);
  }
}

export const getTokenImageByURI = async (uri) => {
  try {
    const metadata = await getTokenMetadata(uri);
    if (metadata) {
      let imageUrl = metadata.image;
      if (imageUrl.startsWith('ipfs://')) {
        imageUrl = `https://ipfs.io/ipfs/${imageUrl.split('ipfs://')[1]}`;
      }
      return imageUrl;
    }
  } catch (err) {
    console.log('error token image', err);
  }
}